import { useMemo } from 'react'
import { Typography } from '@mui/material'
import { MMPTask } from '@playback-rewards/shared-schemas'

//components
import GenericPlaytimeMMPTask from './GenericPlaytimeMMPTask'
import GenericPlaytimeTask from './GenericPlaytimeTask'
import GenericMMPTask from './GenericMMPTask'
//constants
import { CLAIM_SPECIAL_REWARD_REASON } from 'constants/index'
//types
import { GenericOfferProps } from 'types'
//utils
import { getActiveIndex } from 'utils/genericOffer'

const GenericOffer = ({
  title,
  gameDetails,
  playtimeBatchInfo,
  isGenericPlaytimeMMPOffer,
  onButtonClick,
  onRefresh,
}: GenericOfferProps) => {
  //Playtime
  const batchPlaytimeConfig = useMemo(
    () => gameDetails?.specialOfferDetails?.batchPlaytimeConfig,
    [gameDetails?.specialOfferDetails?.batchPlaytimeConfig]
  )
  const batches: number[] = useMemo(
    () => Array.from({ length: batchPlaytimeConfig?.batchCount || 0 }),
    [batchPlaytimeConfig?.batchCount]
  )
  const activeIndex = useMemo(
    () => getActiveIndex(batches, playtimeBatchInfo),
    [batches, playtimeBatchInfo]
  )
  const allPlaytimeTasksCompleted =
    gameDetails?.activatedOffer?.isInstallAttributed && activeIndex < 0
  //MMP
  const mmpEventTasks = useMemo(() => {
    const tasks = (gameDetails?.activatedOffer?.tasks ||
      gameDetails?.specialOfferDetails?.tasks) as MMPTask[]

    return tasks?.filter(
      (task) => task.taskType === CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT && !task.completed
    )
  }, [gameDetails?.activatedOffer?.tasks, gameDetails.specialOfferDetails?.tasks])

  if (allPlaytimeTasksCompleted && mmpEventTasks?.length <= 0) {
    return
  }

  return (
    <div className="gameDetailsSpecialOfferLoginRewards">
      <div className="gameDetailsSpecialOfferPlaytimeRewardsTitle">
        <Typography variant="h3" mb={1} mx={0.5}>
          {title}
        </Typography>
      </div>
      {/* Generic Playtime MMP Combined Tasks */}
      {isGenericPlaytimeMMPOffer ? (
        <GenericPlaytimeMMPTask
          isInstalled={gameDetails?.activatedOffer?.isInstallAttributed}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          batchPlaytimeConfig={batchPlaytimeConfig!}
          activeIndex={activeIndex}
          allPlaytimeTasksCompleted={allPlaytimeTasksCompleted}
          batches={batches}
          mmpEventTasks={mmpEventTasks}
          playtimeBatchInfo={playtimeBatchInfo}
          onButtonClick={onButtonClick}
          onRefresh={onRefresh}
        />
      ) : (
        <>
          {/* Generic Playtime Tasks */}
          {batchPlaytimeConfig &&
            !allPlaytimeTasksCompleted &&
            batches.map((_, index) => {
              return (
                index >= activeIndex &&
                index <= activeIndex + 2 && (
                  <GenericPlaytimeTask
                    key={`playtime-${index}`}
                    index={index}
                    activeIndex={activeIndex}
                    batchPlaytimeConfig={batchPlaytimeConfig}
                    playtimeBatchInfo={playtimeBatchInfo}
                    onRefresh={onRefresh}
                  />
                )
              )
            })}
          {/* Generic MMP Tasks */}
          {mmpEventTasks?.map((task, index) => {
            return (
              !task.completed && (
                <GenericMMPTask
                  key={`mmpevent-${task.taskId}-${index}`}
                  isInstalled={gameDetails?.activatedOffer?.isInstallAttributed}
                  allMMPTasks={mmpEventTasks}
                  mmpTask={task}
                />
              )
            )
          })}
        </>
      )}
    </div>
  )
}

export default GenericOffer
