import React, { useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'

//others
import AuthLayout from '../../components/layout/Auth'
import { AUTHORIZATION_STATE } from '../../constants'
import GoogleAuthButton from '../../components/auth/GoogleAuthButton'
import { generateAuthLinkPost } from '../../services/authService'
import { handleError, handleRNLogin } from '../../utils/utils'
import AuthorizationTerms from '../../components/AuthorizationTerms'
import { getGeoData } from '../../services/geoDataService'

const Content = ({ signUpParams, setState, segmentConfig }) => {
  const handleClick = useCallback(async () => {
    const ipGeolocationResponse = await getGeoData()

    const userGeoInfo = {
      ip: ipGeolocationResponse.data.ip,
      country: ipGeolocationResponse.data.country_code2,
    }

    if (window.ReactNativeWebView) {
      return handleRNLogin(userGeoInfo)
    }

    const authLink = await generateAuthLinkPost('auth/user', [], signUpParams, null).catch(
      handleError
    )
    window.location.href = authLink
  })

  const generatePageContent = () => {
    const pageContent = {
      title: 'Limited Time',
      text: (
        <Typography variant="h3" sx={styles.text}>
          Sign up today to start earning rewards for all of your future mobile game purchases!
        </Typography>
      ),
      image: <img src="/images/authorizationImage.png" className="authorizationImage" />,
    }

    if (segmentConfig.config) {
      pageContent.title = `Hi there, ${segmentConfig.config.segmentGameName} player!`

      if (segmentConfig.segmentConfigValid) {
        pageContent.text = (
          <Typography variant="h3" sx={styles.text}>
            Create a Playback account to earn rewards on your next purchase.{' '}
          </Typography>
        )
      } else {
        pageContent.text = (
          <Typography variant="h3" sx={styles.text}>
            Unfortunately, this offer has expired. However, your game will likely have offers
            available to more players in the future through Playback.
            <br />
            <br />
            Create a Playback account now and you’ll qualify for future offers when they become
            available.
          </Typography>
        )
        pageContent.image = <></>
      }
    }

    return pageContent
  }
  const pageContent = generatePageContent()

  return (
    <AuthLayout>
      <div>
        {pageContent.image}
        <div className="authorizationContent">
          <Typography variant="h1" sx={styles.title}>
            {pageContent.title}
          </Typography>
          {pageContent.text}
          <div className="authorizationContentActionsButtons">
            <GoogleAuthButton handleClick={handleClick} />
            <Button
              sx={{ ...styles.button, ...styles.authorizationButton }}
              onClick={() => setState(AUTHORIZATION_STATE.DONT_HAVE)}
            >
              <Typography variant="h4" sx={styles.authorizationButtonText}>
                Don't have a gmail account?
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Box sx={styles.footer}>
          <Button sx={styles.button} onClick={handleClick}>
            <Typography variant="h4" sx={styles.authorizationButtonText}>
              Already have an account? <strong>Log in</strong>
            </Typography>
          </Button>
          <AuthorizationTerms />
        </Box>
      </div>
    </AuthLayout>
  )
}

const styles = {
  text: { fontWeight: 400, marginBottom: '30px' },
  title: {
    fontWeight: 600,
    marginBottom: '24px',
  },
  button: {
    padding: '7px 24px',
    width: '100%',
    margin: '0 auto',
    background: 'none',
    textAlign: 'center',
    color: '#71717a',
    textTransform: 'none',
    borderRadius: '100px',
    fontWeight: 500,
  },
  authorizationButton: {
    padding: '12px 24px',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  authorizationButtonText: {
    fontWeight: 400,
    fontFamily: 'var(--inter-font)',
    color: '#71717A',
  },
  footer: {
    marginBottom: '17px',
  },
}

export default Content
