import { DateTime, Duration } from 'luxon'
import { getMinutesInBatch, PlaytimeBatchInfo } from '@playback-rewards/shared-libraries'
import { ActivatedOffer, BatchPlaytimeConfig, MMPTask } from '@playback-rewards/shared-schemas'

//constants
import { CLAIM_SPECIAL_REWARD_REASON } from 'constants/index'
//types
import { SpecialOfferDetails } from 'types'

export const getActiveIndex = (
  batches: number[],
  playtimeBatchInfo: PlaytimeBatchInfo | undefined
) => {
  if (!playtimeBatchInfo?.activatedOffer?.isInstallAttributed) {
    return -1
  }
  return batches.findIndex((_, index) => {
    const batchStartTime = playtimeBatchInfo?.getBatchStartTime(index) || DateTime.now().toJSDate()
    const batchEndTime = playtimeBatchInfo?.getBatchEndTime(index) || DateTime.now().toJSDate()
    const luxonStartTime = DateTime.fromJSDate(batchStartTime)
    const luxonEndTime = DateTime.fromJSDate(batchEndTime)
    const luxonCurrentTime = DateTime.now()
    return luxonCurrentTime > luxonStartTime && luxonCurrentTime < luxonEndTime
  })
}

export const getTimeDifference = (endTime: DateTime, startTime: DateTime) => {
  return endTime.diff(startTime, ['hours', 'minutes', 'seconds'])
}

export const getTimeValue = (time: Duration | undefined) => {
  if (!time) {
    return ''
  } else if (time?.hours > 0) {
    return `${Math.round(time.hours)}hr`
  } else if (time.minutes > 0) {
    return `${Math.round(time.minutes)}min`
  } else if (time.seconds > 0) {
    return `${Math.round(time.seconds)}sec`
  }
  return ''
}

export const checkIfGenericPlaytimeMMPOffer = (offer: SpecialOfferDetails | ActivatedOffer) => {
  return (
    Boolean(offer?.batchPlaytimeConfig) &&
    offer?.tasks?.some((task) => task.taskType === CLAIM_SPECIAL_REWARD_REASON.MMP_EVENT)
  )
}

export const checkIfPlaytimeTaskCompleted = (
  batchPlaytimeConfig: BatchPlaytimeConfig,
  playtimeBatchInfo: PlaytimeBatchInfo | undefined,
  index: number
) => {
  if (!playtimeBatchInfo || index < 0) {
    return true
  }
  const minutesInBatch = getMinutesInBatch(batchPlaytimeConfig) || 0
  const remainingMinutes = Math.ceil(playtimeBatchInfo?.getRemainingMinutesInBatch(index) || 0)
  const progress = Math.round(((minutesInBatch - remainingMinutes) / minutesInBatch) * 100)
  return progress >= 100
}

export const isLockedMMPTask = (mmpTask: MMPTask, allMMPTasks: MMPTask[]) => {
  return (
    mmpTask.previousTaskRequirements
      .map((requirement) =>
        allMMPTasks.find((event) => event.taskId === requirement && !event.completed)
      )
      .filter((event) => event !== undefined)?.length > 0
  )
}
