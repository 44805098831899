import { useContext, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { captureEvent } from '@sentry/react'

import { decodeToken } from 'react-jwt'
import { getTokens } from '../../services/authService'
import AuthContext from '../../context/AuthContext'
import { ERRORS } from '../../constants'
import LoaderContext from '../../context/LoaderContext'
import PageInner from '../../components/layout/PageInner'

const Oauth = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const authContext = useContext(AuthContext)
  const { showLoader, hideLoader } = useContext(LoaderContext)

  useEffect(() => {
    const { code, scope, authuser, prompt, error } = Object.fromEntries(searchParams.entries())

    if (error) {
      // TODO: Delete later
      // added temporarily to detect other authorization errors
      captureEvent({
        message: ERRORS.AUTH_ERROR,
        level: 'error',
        extra: { err: error },
      })

      return navigate(`/auth`, { replace: true })
    }

    showLoader()

    getTokens({ code, scope, authuser, prompt }, 'google')
      .then((resp) => {
        // TODO: This needs to be moved to be set in memory only!
        localStorage.setItem('jwtToken', resp.accessToken)
        authContext.login(resp.accessToken, true)

        //This means that user has a null or undefined country and we send them to the country selector
        const unverifiedCountry = decodeToken(resp.accessToken)?.hasUnverifiedCountry

        if (unverifiedCountry) {
          return navigate(`/select-country`, { replace: true })
        }

        if (resp.redirect) {
          return navigate(resp.redirect, { replace: true })
        } else {
          return navigate(`/games`, { replace: true })
        }
      })
      .catch((err) => {
        if (err.response && err.response.data?.errorCode) {
          switch (err.response.data.errorCode) {
            case 49823:
            case 12983:
              return navigate(
                `/auth?error_message=${encodeURIComponent(
                  `Authorization Error. Error code:${err.response.data.errorCode}`
                )}`
              )
            case 49824:
              return navigate(`/auth?already_connected=${err.response.data.message}`)
            case 12345:
              return navigate(err.response.data.message)
            case 49825:
              return navigate('/connect/gmail')
          }
        } else {
          navigate(`/auth?error_message=${encodeURIComponent('Unknown Authorization Error')}`)
        }
      })
      .finally(hideLoader)
  }, [])

  return <PageInner />
}

export default Oauth
